let host = window.location.href;
let isProduction = host?.includes("devvendor") ? false : false;
console.log(
  isProduction
    ? "🚨🚨🚨🚨🚨🚨LIVE🚨🚨🚨🚨🚨🚨"
    : "🚧🚧🚧🚧🚧DEVELOPMENT🚧🚧🚧🚧🚧🚧"
);

// ********* BASE URL ********
export const BASE_URL = isProduction
  ? "https://apiprodi3.sociohood.com/api"
  : "https://apidev.sociohood.com/api";

// ********* VERSION URL ********
export const VERSION_URL = isProduction
  ? "https://versionprod.sociohood.com/api/"
  : "https://version.sociohood.com/api/";

// ********* ACTIVE VERSION ********
export const ACTIVE_VERSION = isProduction ? "1.0.Production" : "1.1";
