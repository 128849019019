import { combineReducers } from "redux";
import { authenticateUser, liveToken, namingConvention } from "./all-functions";

const reducers = combineReducers({
  user: authenticateUser,
  refreshToken: liveToken,
  name: namingConvention,
});

export default reducers;
