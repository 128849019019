import React from "react";
import AnimationLoader from "../../Assets/gif/AnimationLoader.gif";

const PageLoader = () => {
  return (
    <div className="w-100 vh-100 d-flex justify-content-center align-items-center">
      <img src={AnimationLoader} alt="" loading="lazy" />
    </div>
  );
};

export default PageLoader;
