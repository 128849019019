import axios from "axios";
import { useEffect } from "react";
import { versionEndPoint } from "../../Api/Endpoints";
import { ACTIVE_VERSION, VERSION_URL } from "../../Api/base";


const useVersionCheck = (setisMaintainance) => {
  useEffect(() => {
    const handleCheckVersion = async () => {
      const data = {
        platform: "dashboard",
        projectName: "bizhood",
      };
      try {
        const res = await axios.post(
          VERSION_URL + versionEndPoint.checkVersion,
          data
        );
        if (res?.data?.success) {
          if (res?.data?.server?.version !== ACTIVE_VERSION) {
            console.log("🚷🚷🚷🚷🚷VERISON DID'T MATHCED🚷🚷🚷🚷🚷");
            setisMaintainance(true);
          } else {
            console.log("✅✅✅✅✅VERISON MATHCED✅✅✅✅✅");
            setisMaintainance(
              res?.data?.server ? res?.data?.server?.isMaintenance : true
            );
          }
        }
      } catch (error) {
        console.error("Error checking version", error);
      }
    };
    handleCheckVersion();
  }, []);
};

export default useVersionCheck;
